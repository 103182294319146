import { SVGProps, Ref, forwardRef } from 'react'

const SvgGuide = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M21 23.25H5.25A2.25 2.25 0 0 1 3 21M12 2.25H6a3 3 0 0 0-3 3V21a2.25 2.25 0 0 1 2.25-2.25h15A.75.75 0 0 0 21 18V3a.75.75 0 0 0-.75-.75H18M19.5 23.25v-4.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m18 12.75-3-3-3 3V2.25a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5v10.5Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgGuide)
export default ForwardRef
